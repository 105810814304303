module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","de","fr"],"defaultLanguage":"en","siteUrl":"https://my-follower.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/app/","excludeLanguages":["de","fre","es"],"languages":["en"]},{"matchPath":"/dashboard/","excludeLanguages":["de","fre","es"],"languages":["en"]},{"matchPath":"/logout/","excludeLanguages":["de","fre","es"],"languages":["en"]},{"matchPath":"/success/","excludeLanguages":["de","fre","es"],"languages":["en"]},{"matchPath":"/404/","excludeLanguages":["de","fre","es"],"languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CYJ6WHH5MN"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Follower","short_name":"My Follower","start_url":"/","background_color":"#fff","theme_color":"#ccc","display":"minimal-ui","icon":"src/images/my-follower-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38d9608d183986493f983cc8a7c8ee73"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
